import {
  createEffect,
  createSignal,
  onCleanup,
  type Component,
  type ComponentProps,
} from 'solid-js'

export type Words = Array<string>

export type RotatingWordsProps = {
  words: Words
  interval?: number
} & ComponentProps<'span'>

export const RotatingWords: Component<RotatingWordsProps> = ({
  words,
  interval = 1500,
}) => {
  let randomIndex = Math.floor(Math.random() * words.length)

  const [currentWord, setCurrentWord] = createSignal(words[randomIndex])

  createEffect(() => {
    const timer = setInterval(() => {
      let randomIndex = Math.floor(Math.random() * words.length)
      setCurrentWord(words[randomIndex])
    }, interval)

    onCleanup(() => clearInterval(timer))
  })

  return <span>{currentWord()}</span>
}
